
import ColorText from '@/components/ColorText.vue'
import Nullable from '@/components/Nullable.vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import { CupType, cupDateFormat } from '@/modules/weTradeCup/weTradeCup'
import { HistoryData, Prize, readHistory, readMyRank } from '@/modules/weTradeCup/weTradeCup.api'
import Holder from '@/provider/Holder.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheHistory',
  components: { TheRankNumber, ColorText, Nullable, Holder, Pic },
  props: {},
  setup () {
    const preAble = shallowRef(false)
    const nextAble = shallowRef(false)
    const query = useRoute().query
    const type = query.type ?? CupType.WEEKLY
    const page = shallowRef(0)
    const history = shallowRef<HistoryData | null>(null)
    const mine = shallowRef<Prize | null>(null)

    const read = (adder = 0) => {
      const pageNumber = Math.min(0, page.value + adder)

      if (adder !== 0 && page.value === pageNumber) return

      page.value = pageNumber

      const params = { type, pageNumber }

      readHistory(params)
        .then(resp => history.value = resp)

      readMyRank(params)
        .then(resp => {
          mine.value = resp
        })
    }

    read()

    return {
      history,
      preAble,
      nextAble,
      mine,
      page,
      read,
      cupDateFormat,
    }
  },
})
