/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/27 10:53
* @description
*   PrizeDetail.vue of WeTrade
*/
<template>
<PageWithHeader>
  <template #title><t path="cup_2">WeTrade Cup</t></template>
  <div class="fill df-col" style="color: var(--color-white);background: #230E8A">
    <div>
      <TheCupTabSwitch
        :tabs="[translate('cupprizes_5'), translate('cupprizes_6')]"
        style="margin-bottom: 16px;"
        :active="tabIndex"
        @switch="useRule = !useRule"/>
    </div>
    <div v-if="useRule" class="flex-1 px-16 scroll-y pb-32">
      <div class="p-16 br-lg my-16" style="background: #432CAA;">
        <table class="rule t-center">
          <colgroup>
            <col width="20%">
            <col width="40%">
            <col width="40%">
          </colgroup>
          <tr>
            <th></th>
            <th>
              <Pic src="cup/wt/gold_heap" height="16" width="16" />
              <t as="span" path="cupprizes_7" class="c-white f-bold v-middle ml-4">Gold</t>
            </th>
            <th>
              <Pic src="cup/wt/wallet_mini" height="16" width="16" />
              <t as="span" path="cupprizes_8" class="c-white f-bold v-middle ml-4">Credit</t>
            </th>
          </tr>
          <tr v-for="item in rule" :key="item.rank">
            <td>
              <TheRankNumber :rank="item.rank" />
            </td>
            <td>{{item.goldPrize}}g</td>
            <td><money :v="item.moneyPrize"></money></td>
          </tr>
        </table>
      </div>
      <t as="p" :path="context" multiline #="{td}" custom>
        <p class="c-white mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
      </t>
    </div>
    <TheHistory v-else />
  </div>
</PageWithHeader>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import TheHistory from '@/modules/weTradeCup/components/TheHistory.vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import { rules, CupType } from '@/modules/weTradeCup/weTradeCup'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheCupTabSwitch from '@/modules/weTradeCup/components/TheCupTabSwitch.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'PrizeDetail',
  components: { TheRankNumber, TheHistory, Pic, PageWithHeader, TheCupTabSwitch },
  props: {},
  setup () {
    const query = useRoute().query
    const useRule = shallowRef(query.tab !== '1')
    const tabIndex = shallowRef(query.tab ? Number(query.tab) : 0)
    const type = query.type ?? CupType.WEEKLY
    const rule = rules[type as CupType]
    const context = ['--', 'cupprizes_9', 'cupprizes_10', 'cupprizes_11'][query.type ? +query.type : 0]

    return {
      useRule,
      rule,
      translate,
      context,
      tabIndex,
    }
  },
})
</script>

<style scoped lang="scss">
th {
  height: 40px;
}

td {
  height: 48px;

  &:nth-child(2) {
    border-style: solid;
    border-width: 0 1px;
    border-color: #432CAA;
  }
}

tr:nth-child(2n + 3) {
  background: #381B99;
}

tr:nth-child(2n + 4) {
  background: #3E20A5;
}

.text {
  color: #BCAEFF;
}
</style>
