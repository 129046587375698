/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/27 18:11
* @description
*   TheRankNumber.vue of WeTrade
*/
<template>
<Pic v-if="Number(rank) < 4" :width="size" :height="size" :src="`cup/wt/cup_${suffix}`" />
<Pic v-else :width="size" :height="size" src='cup/wt/cup_other' />
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheRankNumber',
  components: { Pic },
  props: {
    rank: {
      type: [Number, String],
      required: true,
    },
    sm: Boolean,
    byDesc: Boolean,
  },
  computed: {
    suffix (): string {
      const desc = ['', 'gold', 'silver', 'copper']
      const name = desc[this.rank as keyof typeof desc] || 'other'
      return (this.byDesc ? name : this.rank) as string
    },
    size (): number {
      return this.sm ? 16 : 32
    },
  },
})
</script>
