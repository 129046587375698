<template>
<div class="tab-wrap d-f">
  <div id="tab-l" :class="['tab-item', {active:actIndex===0}]" @click="swichTab(0)">{{ tabs[0] }}</div>
  <div id="tab-r" :class="['tab-item', {active:actIndex===1}]" @click="swichTab(1)">{{ tabs[1] }}</div>
  <div id="s-l" ref="sLine" :class="['s-line left',{'right': actIndex===1}]"></div>
</div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, shallowRef } from 'vue'
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'

export default defineComponent({
  name: 'TheCupTabSwitch',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    active: Number,
    home: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['switch'],
  setup (props, ctx) {
    const actIndex = shallowRef(props.active ? props.active : 0)

    const localTab = localGet(keymap.cup.mainTab)
    if (localTab) {
      actIndex.value = Number(localTab)
    }
    const sLine = ref<HTMLElement>()
    const swichTab = (index: number) => {
      if (index === actIndex.value) {
        return false
      }
      actIndex.value = index
      if (props.home) {
        localSet(keymap.cup.mainTab, String(index))
      }

      setLineStyle()
      ctx.emit('switch', index)
    }
    const setLineStyle = () => {
      if (sLine.value) {
        if (actIndex.value === 0) {
          sLine.value.style.left = '25%'
          sLine.value.style.marginLeft = '-58px'
        } else {
          sLine.value.style.left = '75%'
          sLine.value.style.marginLeft = '-58px'
        }
      }
    }
    onMounted(() => {
      setLineStyle()
    })
    return {
      actIndex,
      sLine,
      swichTab,
    }
  },
})
</script>

<style scoped lang="scss">
.tab-wrap{
  min-height: 46px;
  max-height: 46px;
  position: relative;
  .tab-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 15px;
    color: var(--color-white);
    line-height: 24px;
    font-weight: 400;
    &.active{
      font-size: 20px;
      font-weight: bold;
      transition: 0.1s;
    }
  }
  .s-line{
    width: 116px;
    height: 6px;
    background-image: url(/img/cup/wt/d_line@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    transition: 0.3s;
  }
}
</style>
