
import { defineComponent, onMounted, ref, shallowRef } from 'vue'
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'

export default defineComponent({
  name: 'TheCupTabSwitch',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    active: Number,
    home: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['switch'],
  setup (props, ctx) {
    const actIndex = shallowRef(props.active ? props.active : 0)

    const localTab = localGet(keymap.cup.mainTab)
    if (localTab) {
      actIndex.value = Number(localTab)
    }
    const sLine = ref<HTMLElement>()
    const swichTab = (index: number) => {
      if (index === actIndex.value) {
        return false
      }
      actIndex.value = index
      if (props.home) {
        localSet(keymap.cup.mainTab, String(index))
      }

      setLineStyle()
      ctx.emit('switch', index)
    }
    const setLineStyle = () => {
      if (sLine.value) {
        if (actIndex.value === 0) {
          sLine.value.style.left = '25%'
          sLine.value.style.marginLeft = '-58px'
        } else {
          sLine.value.style.left = '75%'
          sLine.value.style.marginLeft = '-58px'
        }
      }
    }
    onMounted(() => {
      setLineStyle()
    })
    return {
      actIndex,
      sLine,
      swichTab,
    }
  },
})
