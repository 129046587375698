/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/29 13:06
* @description
*   OpenOperationDialog.vue of WeTrade
*/
<template>
<DialogTemplate>
  <template #title>
    <t path="cup_28">Confirmation</t>
  </template>
  <div class="px-16 df-col df-middle df-center" style="width: 100%">
    <div class="con-t df-middle df-center" >
      <Pic src="cup/wt/coupon" width="56" height="56" />
      <t class="f-bold ml-8" path="cup_29">per day</t>
    </div>
    <t class="mt-16 pb-32" :path="context"></t>
  </div>
  <template #cancelText>
    <t path="cup_32" #="{td}" custom>{{td || 'NO'}}</t>
  </template>
  <template #confirm>
    <Button class="flex-1 btn block primary" :progress="progress" @click="open">
      <t path="cup_33" #="{td}" custom>{{td || 'YES'}}</t>
    </Button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { openIt, readMyPrize } from '@/modules/weTradeCup/weTradeCup.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'OpenOperationDialog',
  components: { Button, DialogTemplate, Pic },
  props: {},
  setup (props, ctx) {
    const [open, progress] = useRequest(openIt)
    const context = shallowRef()
    readMyPrize().then(res => {
      context.value = res.length === 0 ? translate('cup_30') : translate('cup_31')
    })

    return {
      open: () => {
        open()
          .then(() => {
            ctx.emit('confirm')
            ctx.emit('close')
          })
          .catch(() => {
            ctx.emit('cancel')
          })
      },
      progress,
      context,
    }
  },
})
</script>
