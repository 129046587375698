
import Pic from '@/components/Pic.vue'
import TheHistory from '@/modules/weTradeCup/components/TheHistory.vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import { rules, CupType } from '@/modules/weTradeCup/weTradeCup'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheCupTabSwitch from '@/modules/weTradeCup/components/TheCupTabSwitch.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'PrizeDetail',
  components: { TheRankNumber, TheHistory, Pic, PageWithHeader, TheCupTabSwitch },
  props: {},
  setup () {
    const query = useRoute().query
    const useRule = shallowRef(query.tab !== '1')
    const tabIndex = shallowRef(query.tab ? Number(query.tab) : 0)
    const type = query.type ?? CupType.WEEKLY
    const rule = rules[type as CupType]
    const context = ['--', 'cupprizes_9', 'cupprizes_10', 'cupprizes_11'][query.type ? +query.type : 0]

    return {
      useRule,
      rule,
      translate,
      context,
      tabIndex,
    }
  },
})
