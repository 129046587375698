/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/27 16:25
* @description
*   TheHistory.vue of WeTrade
*/
<template>
<div v-if="history">
  <!-- <button @click="load(-1)">prev</button>
  {{history.startDate}}
  -
  {{history.endDate}}
  <button @click="load(1)">next</button> -->
  <div class="date-sel df-middle df-between">
    <div class="change-btn" @click="preAble?'':read(-1)">
      <span class="pre" :style="preAble?'':'border-right-color: var(--color-grey)'"></span>
    </div>
    <div class="date">
      {{cupDateFormat(history.startDate)}}
      -
      {{cupDateFormat(history.endDate)}}
    </div>
    <div class="change-btn" @click="page===0?'':read(1)">
      <span class="next" :style="page===0?'':'border-left-color: var(--color-grey)'"></span>
    </div>
  </div>
</div>
<div class="flex-1 scroll-y px-16">
  <Holder :content="history">
    <Nullable :content="history.results">
      <div v-for="item in history.results" :key="item.rank" class="df-middle t-center column">
        <!-- <div class="col-rank">{{item.rank}}</div> -->
        <div class="col-avatar mr-16">
          <TheRankNumber v-if="item.rank < 4" :rank="item.rank" />
          <span v-else>{{ item.rank }}</span>
        </div>
        <Pic :src="item.avatar" fallback="/img/user/Avatar@2x.png" width="32" height="32" class="mr-16" style="border-radius: 50%;" />
        <div class="flex-1 col-content df-middle">
          <div class="flex-1 t-left">
            {{item.name}}
          </div>
          <ColorText :value="item.rate / 100" class="mr-8 f-bold">
            {{item.rate / 100}}%
          </ColorText>
        </div>
      </div>
    </Nullable>
  </Holder>
</div>
<div v-if="mine" class="mine bg-primary df-middle px-16">
  <div class="col-avatar mx-16">
    <TheRankNumber v-if="mine.rank < 4" :rank="mine.rank" />
    <span v-else>{{ mine.rank }}</span>
  </div>
  <Pic :src="mine.avatar" width="32" height="32" class="mr-16" style="border-radius: 50%;" />
  <div class="flex-1 df-middle">
    <div class="flex-1 t-left">
      {{mine.name}}
    </div>
    <span :value="mine.rate" class="mr-8 f-bold c-white">
      {{mine.rate / 100}}%
    </span>
  </div>
</div>
</template>

<script lang="ts">
import ColorText from '@/components/ColorText.vue'
import Nullable from '@/components/Nullable.vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import { CupType, cupDateFormat } from '@/modules/weTradeCup/weTradeCup'
import { HistoryData, Prize, readHistory, readMyRank } from '@/modules/weTradeCup/weTradeCup.api'
import Holder from '@/provider/Holder.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheHistory',
  components: { TheRankNumber, ColorText, Nullable, Holder, Pic },
  props: {},
  setup () {
    const preAble = shallowRef(false)
    const nextAble = shallowRef(false)
    const query = useRoute().query
    const type = query.type ?? CupType.WEEKLY
    const page = shallowRef(0)
    const history = shallowRef<HistoryData | null>(null)
    const mine = shallowRef<Prize | null>(null)

    const read = (adder = 0) => {
      const pageNumber = Math.min(0, page.value + adder)

      if (adder !== 0 && page.value === pageNumber) return

      page.value = pageNumber

      const params = { type, pageNumber }

      readHistory(params)
        .then(resp => history.value = resp)

      readMyRank(params)
        .then(resp => {
          mine.value = resp
        })
    }

    read()

    return {
      history,
      preAble,
      nextAble,
      mine,
      page,
      read,
      cupDateFormat,
    }
  },
})
</script>

<style scoped lang="scss">
.date-sel {
  height: 40px;
  background: rgba(67, 44, 170, 0.45);

  .change-btn {
    height: 32px;
    display: flex;
    align-items: center;
    width: 32px;
    justify-content: center;
  }

  .pre {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 10px solid;
    border-right-color: #5841D0;
  }

  .next {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 10px solid;
    border-left-color: #5841D0;
  }
}

.col-rank {
  min-width: 16px;
}

.col-avatar {
  min-width: 32px;
}

.col-content {
  height: 56px;
  border-bottom: 1px solid #5236DE;
}

.mine {
  height: 56px;
}
</style>
